import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { Link } from "react-router-dom";

import { authOperations } from "../authorization/duck";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

//import Can from "../config/Can";
import HeaderBar from '../components/HeaderBar';
import './style.scss';
import { withTranslation } from 'react-i18next';

class Homepage extends React.Component {
    
    state = {
        isAuthorizing: false,
        homework: false,
        classwork: false,
        resource: false,
        assignment: false,
        class_feeds: false,
        quiz: false,
        progress_report: false,
        attendance: false
    }

    componentDidMount(){
        this.authorization()
    }
    // api/authorization?username=71e72dfb7a10385b10a363&sessionToken=
    authorization = async() =>{
        this.setState({
            isAuthorizing: true
        })
        const authorizing = await this.props.actions.authorization();
        console.log("---", authorizing)
        if(authorizing.role === 'student'){
            // Feature list of students
            this.setState({
                homework: authorizing.features.indexOf('app-parent-homework')>-1,
                resource: authorizing.features.indexOf('app-parent-resources')>-1,
                assignment: authorizing.features.indexOf('assignment-module-student')>-1,
                class_feeds: authorizing.features.indexOf('classFeeds')>-1,
                quiz: authorizing.features.indexOf('quiz-module-parents')>-1,
               // progress_report: authorizing.features.indexOf('progress-report-module')>-1,
                attendance: authorizing.features.indexOf('headAttendance')>-1,
                classwork: authorizing.features.indexOf('testCalender')>-1,
            })    
        }else{
            // Feature list for teacher
            this.setState({
                homework: authorizing.features.indexOf('app-teacher-homework')>-1,
                resource: authorizing.features.indexOf('app-teacher-resources')>-1,
                assignment: authorizing.features.indexOf('assignment-module-teacher')>-1,
                class_feeds: authorizing.features.indexOf('classFeeds')>-1,
                quiz: authorizing.features.indexOf('quiz-module-teacher')>-1,
                progress_report: authorizing.features.indexOf('progress-report-module')>-1,
                attendance: authorizing.features.indexOf('headAttendance')>-1,
                classwork: authorizing.features.indexOf('testCalender')>-1
            })
        }

        return;
    }

    
    render() {
        const { t } = this.props;
        // CALL THE API HERE
        
        return (
            <div>
                <HeaderBar />
                {/* <div className="mainbox" >
                    <div className="box">
                        <Link to="/homework">
                            <Card>
                                <Card.Img variant="top" src="/imgs/icons/notebook1.png" />
                                <Card.Body>
                                    <Card.Title>Homework</Card.Title>
                                </Card.Body>
                            </Card>
                        </Link>

                        <Link to="/resource">
                            <Card>
                                <Card.Img variant="top" src="/imgs/icons/resourcesIcon.png" />
                                <Card.Body>
                                    <Card.Title>Resource</Card.Title>
                                </Card.Body>
                            </Card>
                        </Link>
                        <Link to='/assignment'>
                            <Card style={{ 'opacity': '100%' }}>
                                <Card.Img variant="top" src="/imgs/icons/result.png" />
                                <Card.Body>
                                    <Card.Title>Assignment</Card.Title>
                                </Card.Body>
                            </Card>
                        </Link>
                    </div>
                    <div className="box">
                        <Link to='/classfeed'>
                            <Card>
                                <Card.Img variant="top" src="/imgs/icons/envelope.png" />
                                <Card.Body>
                                    <Card.Title>Class Feed</Card.Title>
                                </Card.Body>
                            </Card>
                        </Link>
                        <Link to="/quiz">
                            <Card>
                                <Card.Img variant="top" src="/imgs/icons/notebook.png" />
                                <Card.Body>
                                    <Card.Title>Quiz</Card.Title>
                                </Card.Body>
                            </Card>
                        </Link>
                        <Can I='read' a='Attendance'>
                            <Link to="/attendance">
                                <Card>
                                    <Card.Img variant="top" src="/imgs/icons/register.png" />
                                    <Card.Body>
                                        <Card.Title>Attendance</Card.Title>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Can>
                        <Can not I='read' a='Attendance'>
                            <Card style={{ 'opacity': '0%'}}>
                                <Card.Img variant="top" src="/imgs/icons/result.png" />
                                <Card.Body>
                                    <Card.Title></Card.Title>
                                </Card.Body>
                            </Card>
                        </Can>
                    </div>
                </div> */}
                <Container fluid={true}>
                    <Row>
                        {this.state.homework && <Col lg={3} md={3} xs={12} sm={12}>
                            <Link to="/homework">
                                <Card>
                                    <Card.Img variant="top" src="/imgs/icons/notebook1.png" />
                                    <Card.Body className="text-center">
                                        <Card.Title>{t('pages.homework', { action: '' })}</Card.Title>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Col>}

                        {this.state.classwork && <Col lg={3} md={3} xs={12} sm={12}>
                            <Link to="/classwork">
                                <Card>
                                    <Card.Img variant="top" src="/imgs/icons/progress-report.png" />
                                    <Card.Body className="text-center">
                                        <Card.Title>{t('pages.classwork', { action: '' })}</Card.Title>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Col>}

                        {this.state.resource && <Col lg={3} md={3} xs={12} sm={12}>
                            <Link to="/resource">
                                <Card>
                                    <Card.Img variant="top" src="/imgs/icons/resourcesIcon.png" />
                                    <Card.Body className="text-center">
                                        <Card.Title>{t('pages.resource', { action: '' })}</Card.Title>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Col>}

                        {this.state.assignment && <Col lg={3} md={3} xs={12} sm={12}>
                            <Link to='/assignment'>
                                <Card style={{ 'opacity': '100%' }}>
                                    <Card.Img variant="top" src="/imgs/icons/result.png" />
                                    <Card.Body className="text-center">
                                        <Card.Title>{t('pages.assignment', { action: '' })}</Card.Title>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Col>}                      
                    </Row>

                    <Row>
                        {this.state.class_feeds && <Col lg={3} md={3} xs={12} sm={12}>
                        <Link to='/classfeed'>
                                <Card>
                                    <Card.Img variant="top" src="/imgs/icons/envelope.png" />
                                    <Card.Body className="text-center">
                                        <Card.Title>{t('pages.classFeed', { action: '' })}</Card.Title>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Col>}

                        {this.state.attendance && <Col lg={3} md={3} xs={12} sm={12}>
                                <Link to="/attendance">
                                    <Card>
                                        <Card.Img variant="top" src="/imgs/icons/register.png" />
                                        <Card.Body className="text-center">
                                            <Card.Title>{t('pages.attendance', { action: '' })}</Card.Title>
                                        </Card.Body>
                                    </Card>
                                </Link>
                        </Col>}

                        {this.state.quiz && <Col lg={3} md={3} xs={12} sm={12}>
                            <Link to="/quiz">
                                <Card>
                                    <Card.Img variant="top" src="/imgs/icons/about.png" />
                                    <Card.Body className="text-center">
                                        <Card.Title>{t('pages.quiz', { action: '' })}</Card.Title>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Col>}     

                        {<Col lg={3} md={3} xs={12} sm={12}>
                        <Link to="/class-marking">
                                    <Card>
                                        <Card.Img variant="top" src="/imgs/icons/progress-report.png" />
                                        <Card.Body className="text-center">
                                            <Card.Title>{t('pages.update_class_report', { action: '' })}</Card.Title>
                                        </Card.Body>
                                    </Card>
                                </Link>
                        </Col>}
                                  
                    </Row>  
                    <Row>
                    {<Col lg={3} md={3} xs={12} sm={12}>
                        <Link to="/progress-report-students">
                                    <Card>
                                        <Card.Img variant="top" src="/imgs/icons/progress-report.png" />
                                        <Card.Body className="text-center">
                                            <Card.Title>{t('pages.progress_reports', { action: '' })}</Card.Title>
                                        </Card.Body>
                                    </Card>
                                </Link>
                        </Col>}    
                    {<Col lg={3} md={3} xs={12} sm={12}>
                        <Link to="/report-remarks">
                                    <Card>
                                        <Card.Img variant="top" src="/imgs/icons/progress-report.png" />
                                        <Card.Body className="text-center">
                                            <Card.Title>{t('pages.remarks', { action: '' })}</Card.Title>
                                        </Card.Body>
                                    </Card>
                                </Link>
                        </Col>}              
                    </Row>        
             
                </Container>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return { auth: state.auth }
  }

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            authorization: authOperations.isAuthorize,
        }, dispatch)
    };
}
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Homepage));