import React from 'react';
import { withTranslation } from 'react-i18next';

import HeaderBar from '../../components/HeaderBar';
import { Container, Row, Col, Button, Toast } from 'react-bootstrap';

import { fetchClasses, fetchSubject, getProgressHeads, getProgressMarking, saveProgressMarking } from '../../components/duck/operations';

import './style.scss';
import { bindActionCreators } from 'redux';
import { connect, FontAwesomeIcon, faCircleNotch } from '../../quiz';

class UpdateMarking extends React.Component {
    state = {
        classes: [],
        subjects: [],
        scores: [],
        reports: [],
        sessions: ['2020', '2021', '2022', '2023', '2024','2025','2026'],
        APIBeingCalled: false,
        localize: this.props.t,
        session: null, classID: null, subjectID: null, scoreID: null,
        toast: {
            show: false,
            title: null,
            message: null
        },
        searchString: '',
        isMarkingValid: false,
        message: this.props.t('progressReportUpdateComponent.static.selectFilter')
    }

    componentDidMount() {
        const role = localStorage.getItem('role');

        if (role !== 'student') {
            this.getClasses();
        }
    }

    /* SELECTIONS */

    onSelectClass = (e) => {
        if (e.target.value) {
            if (e.target.value !== "Select Class") {
                this.setState({ classID: e.target.value }, () => this.getSubjects());
            } else {
                this.setState({ classID: null });
            }
        }
    }

    onSelectSubject = (e) => {
        if (e.target.value) {
            if (e.target.value !== "Select Subject") {
                this.setState({ subjectID: e.target.value });
            } else {
                this.setState({ subjectID: null });
            }
        }
    }

    onSelectSession = (e) => {
        if (e.target.value) {
            if (e.target.value !== "Select Session") {
                this.setState(
                    { session: e.target.value },
                    () => {
                        if (this.state.classID && this.state.subjectID && this.state.session) {
                            this.getScores();
                        }
                    }
                );
            } else {
                this.setState({ session: null });
            }
        }
    }

    onSelectScore = (e) => {
        if (e.target.value) {
            if (e.target.value !== "Select Score") {
                this.setState({ scoreID: e.target.value });
            } else {
                this.setState({ scoreID: null });
            }
        }
    }

    /* BUTTON EVENTS */

    getReports = async () => {
        this.setState({ APIBeingCalled: true });

        try {
            const reports = await this.props.actions.getProgressMarking(this.state.classID, this.state.subjectID, this.state.scoreID, this.state.session);

            if (reports.list.length > 0) {
                this.setState({ reports: reports.list });
            }
        } catch (error) {
            this.showToast(this.state.localize('shared.static.warning'), error?.response?.data?.message)
        }

        this.setState({ APIBeingCalled: false });
    }

    /* SELECT FIELDS */

    getClasses = async () => {
        this.setState({ APIBeingCalled: true });
        try {
            const response = await this.props.actions.fetchClasses();

            if (response) {
                this.setState({
                    classes: response
                });
            }
        } catch (error) {
            this.showToast(this.state.localize('shared.static.warning'), error?.response?.data?.message)
        }

        this.setState({ APIBeingCalled: false });
    }

    getSubjects = async () => {
        this.setState({ APIBeingCalled: true });

        try {
            const subjects = await this.props.actions.fetchSubject(this.state.classID);
            if (subjects.length > 0) {
                this.setState({ subjects });
            }
        } catch (error) {
            this.showToast(this.state.localize('shared.static.warning'), error?.response?.data?.message)
        }

        this.setState({ APIBeingCalled: false });
    }

    getScores = async () => {
        this.setState({ APIBeingCalled: true });

        try {
            const scores = await this.props.actions.getProgressHeads(this.state.classID,this.state.subjectID,this.state.session);
            if (scores.list.length > 0) {
                this.setState({ scores: scores.list });
            }
        } catch (error) {
            this.showToast(this.state.localize('shared.static.warning'), error?.response?.data?.message)
        }

        this.setState({ APIBeingCalled: false });
    }

    /* TOAST */

    showToast = (title, message) => {
        this.setState({
            toast: {
                show: true,
                title: title,
                message: message || this.state.localize('shared.static.serverError')
            }
        });

        setTimeout(this.hideToast, 3000);
    }

    hideToast = () => {
        this.setState({
            toast: {
                show: false,
                message: null,
                title: null
            },
            APIBeingCalled: false
        })
    }

    /* INPUT FIELDS */

    searchStudents = (event) => {
        const searchString = event.target.value;

        this.setState({
            searchString: searchString
        })
    }

    editMarks = (index, event) => {
        const reports = [...this.state.reports];
        if (reports[index]) {
            reports[index].obtain_marks = event.target.value;

            this.setState({
                reports
            });
        }
    }

    saveMarks = async () => {
        this.setState({ APIBeingCalled: true });

        try {
            let updateProgressPayload = {
                class_id: this.state.classID,
                head_id: this.state.scoreID,
                session: this.state.session,
                student_progress: [],
                subject_id: this.state.subjectID,
                template_id: this.state.reports[0].template_id
            };

            const reports = [...this.state.reports];

            for(let i = 0; i < reports.length; i++) {
                updateProgressPayload.student_progress.push({
                    student_id: reports[i].student.student_id,
                    obtain_marks: reports[i].obtain_marks
                })
            }

            const updateProgress = await this.props.actions.saveProgressMarking(updateProgressPayload);
            if (updateProgress.success) {
                this.showToast('Success', updateProgress.message)

                this.getReports();
            }
        } catch (error) {
            console.log(error)

            this.showToast(this.state.localize('shared.static.warning'), error?.response?.data?.message)
        }

        this.setState({ APIBeingCalled: false });
    }

    /* VALIDATIONS */

    matchesSearchString = (report) => JSON.stringify(report).toLowerCase().includes(this.state.searchString);
    invalidMarking = (marking, total_marks = 100) => {
        let number = Number.parseInt(marking);

        if (isNaN(number)) {
            return { invalid: true, message: this.state.localize('progressReportUpdateComponent.validation.required') }
        }

        if (number > total_marks) {
            return { invalid: true, message: this.state.localize('progressReportUpdateComponent.validation.exceed', { marks: total_marks }) }
        }

        if (number < 0) {
            return { invalid: true, message: this.state.localize('progressReportUpdateComponent.validation.less') }
        }

        return { invalid: false, message: '' };
    };

    isEveryMarkingValid = () => {
        const marking = [...this.state.reports];

        const valid = !marking.every((m) => this.invalidMarking(m.obtain_marks, m.total_marks).invalid === false); 
        
        return valid;
    }
    

    /* RENDER */

    renderReportListing = () => {
        if (this.state.reports.length < 1) {
            return <Container fluid={true}>
                <Row>
                    <Col md={12} lg={12} sm={12} xs={12} className="component-message width-100 align-items-center justify-content-center">
                        <h4>{ this.state.message }</h4>
                    </Col>
                </Row>
            </Container>;
        }

        return <>
            {
                this.state.reports.length > 0 &&
                <Row className="mt-4">
                    <Col md={12} lg={12} xs={12} sm={12}>
                        <input type="text" placeholder={this.state.localize('progressReportUpdateComponent.placeholders.searchStudents')} className="form-control form-sm" onChange={this.searchStudents} />
                    </Col>
                </Row>
            }

            {
                this.state.reports.length > 0 &&
                <div className="scrollable">
                    {
                        this.state.reports.map((report, index) => {
                            return (
                                this.matchesSearchString(report) &&
        
                                <Row key={index} className="mt-2 mr-2">
                                    <Col md={10} lg={10} xs={12} sm={12}>
                                        <p>
                                            {report.student.firstName} {report.student.lastName}
                                        </p>
                                    </Col>
        
                                    <Col md={2} lg={2} xs={12} sm={12} className="d-flex justify-content-end gap-5 d-flex flex-column text-nowrap">
                                        {/* <div className="d-flex justify-content-end gap-5 d-flex">
                                            <div>
                                                <input type="text" className="form-control form-sm" value={report.obtain_marks} onChange={(e) => this.editMarks(index, e)} />
                                            </div>
        
                                            <div className="d-flex justify-content-center align-items-center">
                                                / {report.total_marks}
                                            </div>
                                        </div> */}
                                        <span className="d-flex gap-5">
                                            <input type="text" className="form-control form-sm" value={report.obtain_marks} onChange={(e) => this.editMarks(index, e)} />

                                            <p>/ {report.total_marks}</p>
                                        </span>
        
                                        {this.invalidMarking(report.obtain_marks, report.total_marks).invalid && <small className="align-self-end">{this.invalidMarking(report.obtain_marks, report.total_marks).message}</small>}
                                    </Col>
                                </Row>
                            )
                        })
                    }
                </div>
            }

            {
                this.state.reports.length > 0 &&
                <Row className='mt-2 mb-2 text-align-right save-button'>
                    <Col md={12} lg={12} xs={12} sm={12}>
                        <Button variant="primary" disabled={this.isEveryMarkingValid()} onClick={this.saveMarks}>{ this.state.localize('shared.buttons.save', { type: ''}) }</Button>
                    </Col>
                </Row>
            }
        </>

    }

    render() {
        return (
            <div>
                <HeaderBar />
                <div
                    aria-live="polite"
                    aria-atomic="true">
                    <div
                        style={{
                            marginTop: '10px',
                            marginRight: '10px',
                            position: 'fixed',
                            top: 0,
                            right: 0,
                            zIndex: '9999',
                            width: '280px'
                        }}
                    >
                        <Toast show={this.state.toast.show}>
                            <Toast.Header>
                                <strong className="mr-auto">{this.state.toast.title}</strong>
                            </Toast.Header>
                            <Toast.Body>{this.state.toast.message}</Toast.Body>
                        </Toast>
                    </div>
                </div>
                <Container fluid={true}>
                    <Row className="header__wrapper">
                        <Col md={3} lg={3} xs={12} sm={12}>
                            <h4>
                                { this.state.localize('progressReportUpdateComponent.static.title') }
                            </h4>
                        </Col>

                        <Col md={9} lg={9} xs={12} sm={12} className="d-flex justify-content-end gap-5">
                            <select className="form-control form-sm" disabled={this.state.APIBeingCalled} onChange={this.onSelectClass}>
                                <option>{this.state.localize('shared.texts.select', { type: this.state.localize('shared.static.class') })}</option>
                                {this.state.classes.map((c) => <option value={c._id} key={c._id}>{c.name}</option>)}
                            </select>

                            <select className="form-control form-sm" disabled={this.state.APIBeingCalled || this.state.classes.length === 0} onChange={this.onSelectSubject}>
                                <option>{this.state.localize('shared.texts.select', { type: this.state.localize('shared.static.subject') })}</option>
                                {this.state.subjects.map((s) => <option value={s._id} key={s._id}>{s.title}</option>)}
                            </select>

                            <select className="form-control form-sm" disabled={this.state.APIBeingCalled} onChange={this.onSelectSession}>
                                <option>{this.state.localize('shared.texts.select', { type: this.state.localize('shared.static.session') })}</option>
                                {this.state.sessions.map((s, i) => <option value={s} key={i}>{s}</option>)}
                            </select>

                            <select className="form-control form-sm" disabled={this.state.APIBeingCalled || (!this.state.classID || !this.state.session)} onChange={this.onSelectScore}>
                                <option>{this.state.localize('shared.texts.select', { type: this.state.localize('shared.static.score') })}</option>
                                {this.state.scores.map((s) => <option value={s._id} key={s._id}>{s.title}</option>)}
                            </select>

                            <Button variant="primary" onClick={this.getReports} disabled={this.state.APIBeingCalled || (!this.state.classID || !this.state.subjectID || !this.state.session || !this.state.scoreID)}> {this.state.localize('shared.buttons.search')}</Button>
                        </Col>
                    </Row>

                    <>
                        {
                            !this.state.APIBeingCalled ?
                                this.renderReportListing()
                                :
                                <div className="text-xl-center">
                                    <FontAwesomeIcon style={{ width: '200px', height: '200px', color: '#5bc0de' }} icon={faCircleNotch} spin />
                                </div>
                        }
                    </>
                </Container>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        resourceList: state.componentReducer.resourceList
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            fetchClasses: fetchClasses,
            fetchSubject: fetchSubject,
            getProgressHeads: getProgressHeads,
            getProgressMarking: getProgressMarking,
            saveProgressMarking: saveProgressMarking
        }, dispatch)
    };
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(UpdateMarking));