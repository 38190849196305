import { authActions } from './index';
import { apiService } from "./../../common/utils/api-service";
// import { accountActions } from "../../account/duck";
// import { snackbarActions } from "../../snackbar/duck";

const login = (data) => (dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch(authActions.loginRequest());
        apiService('get', 'login/login_code', null, null, data)
        .then((res) => {
            let responseData = res.data;
            localStorage.setItem('role', responseData.user.role)
            localStorage.setItem('x_token', responseData.token)
            localStorage.setItem('x_user', responseData.user.username)
            localStorage.setItem('schoolID', responseData.user.schoolID)

            localStorage.setItem('schoolType', responseData.schoolType.type)
            localStorage.setItem('firstName', responseData.user.firstName)
            localStorage.setItem('lastName', responseData.user.lastName)
            // localStorage.setItem('teacherID', responseData.user._id)
            localStorage.setItem('userID', responseData.user._id)
            //localStorage.setItem('classID', responseData.profile.classID)
            dispatch(authActions.loginSuccess(responseData));
            dispatch(authActions.UserloginSuccess(responseData.user.role));
            resolve(responseData)
    }, (err) => {
            let errorResponse = err;
//            let errorResponse = err.response.data;
            reject(errorResponse);
        })
    })
};

const getToken = () => (dispatch) => {
    return new Promise((resolve) => {
        const token = localStorage.getItem('role');
        if (token) {
            dispatch(authActions.saveToken(token));
        }
        resolve(token)
    })
};

const unauthorize = () => (dispatch) => {
    return new Promise(() => {
        localStorage.removeItem('token');
        dispatch(authActions.unauthorize());
    })
};

const isAuthorize = () => (dispatch) =>{
    dispatch(authActions.isAuthorizing())
    return new Promise(async (resolve, reject)=>{
        const token= localStorage.getItem('x_token'),
        xUsername=localStorage.getItem('x_user');
        const authorizing = await apiService('get', 'authorization?username='+xUsername+'&sessionToken='+token, null, null, null)
        if(authorizing.data.success){
            return resolve(authorizing.data.data)
        }

        reject({})
    })
}

const logout = () => { localStorage.clear() }

export {
    login,
    getToken,
    unauthorize,
    logout,
    isAuthorize
}

// const setToken = (token) => (dispatch) => {
//     return new Promise((resolve) => {
//         localStorage.setItem('role', token)
//         resolve()
//     })
// };

/*const logout = () => (dispatch) => {
    return new Promise((resolve) => {
        //localStorage.removeItem('token');
        //dispatch(authActions.logout());
        // dispatch(accountActions.clearUserInfo());
        resolve(true)
    })
};*/

/*const login = (data) => (dispatch) => {
    return new Promise((resolve, reject) => {
        
        //Spinner start
        // dispatch(authActions.loginRequest());
        apiService('get', 'login/login_code', null, null, data)
        .then((res) => {
            let responseData = res.data;
            //Toast bnana hai idher
            //Spinner stop
            //if (responseData.data.success === 200){console.log("Success")} 
            // dispatch(accountActions.getUserInfoSuccess(responseData.data))
            localStorage.setItem('token', responseData.data.accessToken)
            localStorage.setItem('user', responseData.data.user)
            dispatch(authActions.loginSuccess(responseData.data));
            resolve(responseData)
        }, (err) => {
            let errorResponse = err.response.data;
            // dispatch(authActions.loginFailed(errorResponse));
            // dispatch(snackbarActions.showSnackbar({
            //     message: errorResponse.message,
            //     type: 'danger'
            // }))
            reject(errorResponse);
        })
    })
};*/