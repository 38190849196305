import React from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col, Button, Modal } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faCircleNotch, faTimes } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment';
import 'moment/locale/en-au';  // without this line it didn't work
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
//import i18n from '../i18n';


import { uploadImage, uploadPDF, updateHomework, fetchSubject } from '../components/duck/operations';

import { withTranslation } from 'react-i18next';
var textEditorContent = '';
class homework extends React.Component {
   
    state = {
        show: false,
        isFileNotAttached: true,
        diaryContent: "No Description",
        files: [],
        isUpdatingHomework: false,
        indexOfHomework: 0,
        classes: [], subjects: [],
        classID: '', subjectID: '',
        localize: this.props.t,
        assignedLabelDate: "No Description"
    }

    onClose = e => { this.props.onClose && this.props.onClose(e) && this.setState({ files: [] }) };

    handleDescription = (data) => {
       textEditorContent = data;
    }

    handleFiles = (event) => { this.setState({ files: event.target.files, isFileNotAttached: false }) }

    removeFile = (i) => {
        let homework = { ...this.state.homework };
        homework.diaryImage.splice(i, 1);
        this.setState({ homework })
    }

    updateHomeworkAPI = async () => {
        let homework = { ...this.state.homework };
        homework.content = textEditorContent;
        this.setState({ homework })

        this.setState({ isUpdatingHomework: true })
        try {
            if (this.validateInformationForCreating()) {
                let stateFiles = [...this.state.files];

                let filesURL = [];

                for (let i = 0; i < stateFiles.length; i++) {
                    if (typeof stateFiles[i] === "object") {
                        if (!stateFiles[i].type.startsWith("image")) {
                            let file = await this.props.actions.uploadPDF(stateFiles[i]);

                            filesURL.push(file.data)
                        } else {
                            let image = await this.props.actions.uploadImage(stateFiles[i]);

                            filesURL.push(image.data);
                        }
                    }
                }

               // let homework = { ...this.state.homework };  DUPLICATING, not needed

                const homeworkPayload = {
                    classID: this.props.classID,
                    diaryID: homework?._id,
                    diaryContent: homework?.content,
                    diaryImage: [...filesURL, ...homework?.diaryImage] ,
                    day: this.state.homework?.day
                };

                let studentHomework = await this.props.actions.updateHomework(homeworkPayload);
                if (studentHomework) {
                    this.setState({ files: [], classes: [], subjects: [] })

                    this.props.handleMessage(this.state.localize('shared.texts.updated', { module: this.state.localize('pages.homework', {action: ''}) }), this.state.localize('shared.static.success'))

                    this.props.onUpdate()
                }
            }
        } catch (error) {
            console.error(error)

            // this.props.handleMessage(error.response.data.message || "Something went wrong.");
        }
        this.setState({ isUpdatingHomework: false })
    }

    validateInformationForCreating = () => {
        if (!this.state.homework.day) {
            this.props.handleMessage(this.state.localize('homeworkComponent.validation.assigned'), this.state.localize('shared.static.warning'))

            return false;
        }

        if (this.state.homework.content.length === 0) {
            this.props.handleMessage(this.state.localize('homeworkComponent.validation.description'), this.state.localize('shared.static.warning'))

            return false;
        }

        return true;
    }

    handleDate = (event) => {
        let homework = { ...this.state.homework };

        homework.day = new Date(event.target.value).getTime();

        this.setState({ homework })
    }

    componentDidUpdate(nextProps) {
        if(nextProps.homework !== this.props.homework && this.props.homework) {
            this.setState({
                homework: this.props.homework
            })
        }
    }

    isImage(fileName) {
        if(!fileName) {
            return false;
        }

        const docFiles = ["docx", "doc", "xls", "xlsx", "pdf"];

        return docFiles.includes(fileName.split(".")[fileName.split(".").length - 1]) ? false : true;
    }

    componentDidMount(content) {  
    
        new Promise( ( resolve ) => {
          setTimeout( resolve, 100 );
        } ).then( () => {
          this.setState( { desc: content, assignedLabelDate: moment(homework?.day).format('DD-MM-YYYY')} );
        } );
      }

    render() {
       
        const { isUpdatingHomework, homework } = this.state       
        moment.locale('en-au')   
      //  this.setState( { assignedLabelDate: moment(homework?.day).format('DD-MM-YYYY')} );    
        this.componentDidMount(homework?.content);

        return (
            <div>
                <Modal size="lg" show={this.props.show} animation={false} enforceFocus={false}>
                    <Modal.Header>
                        <Modal.Title>{this.state.localize('pages.homework', { action: this.state.localize('shared.static.update')})}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="show-grid">
                        <Container>
                            <Row>
                                <Col xs={12} md={6} lg={4}>
                                    <Modal.Body className="form-group">
                                        <label className="control-label" htmlFor="todaydate">{this.state.localize('homeworkComponent.shared.assignedDate')}</label>
                                        <span className="dateelement displayblock" disabled={true}>{this.state.assignedLabelDate} </span>
                                    </Modal.Body>
                                </Col>
                                <Col xs={12} md={6} lg={4}>
                                    <Modal.Body className="form-group">
                                        <label className="control-label">{this.state.localize('shared.texts.class')}</label>
                                        <select className="form-control form-sm" value={this.props.classID} disabled={true}>
                                            <option>{this.state.localize('shared.texts.select', {type: this.state.localize('shared.static.class')})}</option>
                                            {this.props.classes.map((c) => {
                                                return (
                                                    <option value={c._id} key={c._id}>{c.name}</option>
                                                )
                                            })}
                                        </select>
                                    </Modal.Body>
                                </Col>
                                <Col xs={12} md={6} lg={4}>
                                    <Modal.Body className="form-group">
                                        <label className="control-label">{this.state.localize('shared.texts.subject')}</label>
                                        <select className="form-control form-sm" value={this.props.subjectID} disabled={true}>
                                            <option>{this.state.localize('shared.texts.select', {type: this.state.localize('shared.static.subject')})}</option>
                                            {this.props.subjects.map((s) => {
                                                return (
                                                    <option value={s._id} key={s._id}>{s.title}</option>
                                                )
                                            })}
                                        </select>
                                    </Modal.Body>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={12} lg={12}>
                                    <Modal.Body className="d-flex flex-column">
                                        <div>
                                            <h6>
                                            {/* {this.state.localize('homeworkComponent.shared.clickFiles')} */}
                                            Remove/Update the files attached, if needed
                                            </h6>
                                        </div>
                                        {
                                            homework?.diaryImage?.length > 0 && typeof homework?.diaryImage === "object" &&
                                            <div>
                                                {
                                                    homework?.diaryImage?.map((file, i) => {
                                                        return (
                                                            <div key={i}>
                                                                {
                                                                    this.isImage(file) ?
                                                                        <div style={{ marginBottom: '15px' }}>
                                                                            <img src={file} style={{ width: '128px', height: '128px' }} alt="student-assignment" />
                                                                            <FontAwesomeIcon icon={faTimes} style={{ float: 'right', color: 'red', fontSize: '28px' }} onClick={() => this.removeFile(i)} />
                                                                        </div>
                                                                        :
                                                                        <div style={{ marginBottom: '15px' }}>
                                                                            <a href={file} rel="noopener noreferrer" style={{ width: '128px', height: '128px' }} target="_blank">{this.state.localize('shared.static.files')}</a>
                                                                            <FontAwesomeIcon icon={faTimes} style={{ float: 'right', color: 'red', fontSize: '28px' }} onClick={() => this.removeFile(i)} />
                                                                        </div>
                                                                }
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        }

                                        {
                                            homework?.diaryImage?.length > 0 ?
                                                <label>
                                                    {this.state.localize('shared.texts.select', { type: this.state.localize('shared.static.files')})}
                                                </label>
                                                :
                                                ''
                                        }
                                        <input type="file" onChange={this.handleFiles} multiple />
                                    </Modal.Body>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={12} lg={12}>
                                    <Modal.Body>
                                        <label> {this.state.localize('homeworkComponent.shared.description')} </label>
                                        {/* <textarea onChange={this.handleDescription} value={homework?.diary[indexOfHomework]?.content}></textarea> */}
                                        <CKEditor 
                                            editor={ClassicEditor} 
                                            data={this.state.desc}
                                            onChange={(event, editor) => {                                              
                                                const data = editor.getData();
                                                this.handleDescription(data);                                        
                                            }}
                                        />
                                    </Modal.Body>
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={this.onClose}>
                            {this.state.localize('shared.buttons.close')}
                        </Button>

                        <Button
                            variant="success"
                            onClick={this.updateHomeworkAPI}
                            disabled={isUpdatingHomework}>
                            {!isUpdatingHomework ? <FontAwesomeIcon icon={faPlus} /> : <FontAwesomeIcon icon={faCircleNotch} spin />}
                            {!isUpdatingHomework ? (' ' + this.state.localize('shared.buttons.update')) : (' ' + this.state.localize('shared.buttons.wait'))}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div >
        );
    }
}

function mapStateToProps(state) {
    return {
        // classID: state.componentReducer.classID,
        // subjectID: state.componentReducer.subjectID,
        date: state.componentReducer.date,
        image: state.componentReducer.image
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            updateHomework: updateHomework,
            uploadImage: uploadImage,
            uploadPDF: uploadPDF,
            fetchSubject: fetchSubject
        }, dispatch)
    };
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(homework));